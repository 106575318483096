import pollo_desktop from "../../../static/assets/images/menu/pollos/desktop/UN_POLLO.jpg"
import pollo from "../../../static/assets/images/menu/pollos/unpollo.jpg"
import pollo_papa from "../../../static/assets/images/menu/pollos/pollosolopapa.jpg"
import pollo_papa_desktop from "../../../static/assets/images/menu/pollos/desktop/POLLO_SOLO_PAPA.jpg"
import medio_pollo from "../../../static/assets/images/menu/pollos/mediopollo.jpg"
import medio_pollo_desktop from "../../../static/assets/images/menu/pollos/desktop/MEDIO_POLLO.jpg"
import medio_pollo_papa from "../../../static/assets/images/menu/pollos/mediopollosolopapa.jpg"
import medio_pollo_papa_desktop from "../../../static/assets/images/menu/pollos/desktop/MEDIO_POLLO_SOLO_PAPA.jpg"
import cuarto_pollo from "../../../static/assets/images/menu/pollos/bandejauncuartodepollo.jpg"
import cuarto_pollo_desktop from "../../../static/assets/images/menu/pollos/desktop/BANDEJA_POLLO.jpg"
import cuarto_pollo_papa from "../../../static/assets/images/menu/pollos/uncuartopollosolopapa.jpg"
import cuarto_pollo_papa_desktop from "../../../static/assets/images/menu/pollos/desktop/CUARTO_POLLO_SOLO_PAPA.jpg"

const pollosData = [
  {
    imageSrc: pollo,
    imageDesktopSrc: pollo_desktop,
    imageDescription: "Un pollo asado con arroz papa y ensalada",
  },
  {
    imageSrc: pollo_papa,
    imageDesktopSrc: pollo_papa_desktop,
    imageDescription: "Un pollo asado con solo papa",
  },
  {
    imageSrc: medio_pollo,
    imageDesktopSrc: medio_pollo_desktop,
    imageDescription: "Medio pollo asado con arroz papa y ensalada",
  },
  {
    imageSrc: medio_pollo_papa,
    imageDesktopSrc: medio_pollo_papa_desktop,
    imageDescription: "Medio pollo asado con solo papa",
  },
  {
    imageSrc: cuarto_pollo,
    imageDesktopSrc: cuarto_pollo_desktop,
    imageDescription: "Cuarto de pollo asado con arroz papa y ensalada",
  },
  {
    imageSrc: cuarto_pollo_papa,
    imageDesktopSrc: cuarto_pollo_papa_desktop,
    imageDescription: "Cuarto de pollo asado con solo papa",
  },
]

export default pollosData
