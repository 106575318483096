import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import Head from "../../components/Head"
import "../../styles/categories.css"
import pollosData from "../../utils/menu/pollosData"

const pollos = () => (
  <>
    <Head
      description="Prueba nuestro pollo asado en sus distintas presentaciones en combo con arroz papa y ensalada o solo con papa frita"
      title="Menu: Pollos - Asadero RoqueFer"
      keywords="pollo entero, medio pollo, cuarto de pollo"
    />
    <Layout>
      <main>
        <div className="products-container">
          <h3>Pollos</h3>
          {pollosData.map(current => {
            return (
              <div className="product">
                <Link to="/pedidos">
                  <picture>
                    <source
                      srcSet={current.imageDesktopSrc}
                      media="(min-width: 500px)"
                    />
                    <img
                      loading="lazy"
                      src={current.imageSrc}
                      alt={current.imageDescription}
                    />
                  </picture>
                </Link>
              </div>
            )
          })}
        </div>
      </main>
    </Layout>
  </>
)

export default pollos
